export class PoliceId {

    constructor(patronAttributeId) {
        if (this.constructor === PoliceId) {
            throw new TypeError('Abstract class "PoliceId" cannot be instantiated directly.');
        }

        this.patronAttributeId = patronAttributeId;
        this.load();
    }

    load() {
        const patronAttributeId = this.patronAttributeId;
        const domTextarea = $(`input[value="${patronAttributeId}"]`)
            .parent()
            .children('textarea');

        if (typeof domTextarea[0] === "undefined") {
            return;
        }

        let domInput = document.createElement('input');

        domInput.value = domTextarea[0].value;
        domInput.id = domTextarea[0].id;
        domInput.name = domTextarea[0].name;

        domTextarea.after(domInput).remove();

        const regex = this.getRegex();
        const minLength = this.getMinLength();
        const maxLength = this.getMaxLength();

        $('#entryform').validate();
        $(`#${domInput.id}`).rules("add", {
            regex: regex,
            minlength: minLength,
            maxlength: maxLength
        });
    }

    getRegex() {
        throw TypeError("Method 'getRegex' not implemented");
    }

    getMinLength() {
        throw TypeError("Method 'getMinLength' not implemented");
    }

    getMaxLength() {
        throw TypeError("Method 'getMaxLength' not implemented");
    }
}