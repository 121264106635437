import {PoliceId} from "../PoliceId";

export class GreekPoliceId extends PoliceId {

    constructor(patronAttributeId) {
        super(patronAttributeId);
    }

    getRegex() {
        const regexCharacterPart = "[α-ω|Α-Ω]{1,2}";
        const regexNumberPart = "[1-9]{6}";

        return `${regexCharacterPart}${regexNumberPart}`;
    }

    getMinLength() {
        return 7;
    }

    getMaxLength() {
        return 8;
    }
}